import { format } from 'timeago.js';
import { formatBytes } from '~/lib/units';

type MaybeNumber = number | null | undefined;

export const displaySpace = (available: MaybeNumber, total: MaybeNumber) => {
  if (!available || !total) return 'n/a';
  return `${formatBytes(total - available)} / ${formatBytes(total)}`;
};

export const displayTemperature = (celsius: MaybeNumber) => {
  if (!celsius) return 'n/a';
  const farenheit = (celsius * 9) / 5 + 32;
  return `${celsius}°C / ${farenheit}°F`;
};

export const displayUptime = (processUptime: MaybeNumber, osUptime: MaybeNumber) => {
  if (!processUptime || !osUptime) return 'n/a';
  const formattedProcessUptime = format(Date.now() - processUptime * 1000).replace(' ago', '');
  const formattedOsUptime = format(Date.now() - osUptime * 1000).replace(' ago', '');
  return `Node: ${formattedProcessUptime}, Server: ${formattedOsUptime}`;
};
