import { Grid } from '@mui/material';
import { useLink } from '~/hooks/link';
import { pluralize } from '~/lib/string';
import {
  DashboardStatsCard as Card,
  DashboardStatsCount as Count,
  DashboardStatsLink as Link,
  DashboardTitle as Title,
} from './styles';

interface DeviceStatProps {
  count: number | undefined | null;
  title: string;
}

const DeviceStat = (props: DeviceStatProps) => {
  const { count, title } = props;

  return (
    <Grid item>
      <div>
        <Count>{count}</Count>
        <Title>{title}</Title>
      </div>
    </Grid>
  );
};

export interface DeviceStatsLinkProps {
  activeCount: number;
  errorCount: number;
  healthyCount: number;
  warnCount: number;
  displayOnCount: number;
  displayOffCount: number;
}

export const DeviceStats = (props: DeviceStatsLinkProps) => {
  const { activeCount, errorCount, healthyCount, warnCount, displayOnCount, displayOffCount } =
    props;

  const link = useLink();

  return (
    <Card container>
      <Link to={link('/devices')}>
        <DeviceStat count={activeCount} title={pluralize('Active Device', activeCount)} />
      </Link>
      <Link to={link('/devices?statuses=["HEALTHY"]')}>
        <DeviceStat count={healthyCount} title={pluralize('Healthy Device', healthyCount)} />
      </Link>
      <Link to={link('/devices?statuses=["WARN"]')}>
        <DeviceStat count={warnCount} title={pluralize('Warning Device', warnCount)} />
      </Link>
      <Link to={link('/devices?statuses=["ERROR"]')}>
        <DeviceStat count={errorCount} title={pluralize('Error Device', errorCount)} />
      </Link>
      <Link to={link('/devices?display=[true]')}>
        <DeviceStat count={displayOnCount} title={pluralize('Display', displayOnCount) + ' On'} />
      </Link>
      <Link to={link('/devices?display=[false]')}>
        <DeviceStat
          count={displayOffCount}
          title={pluralize('Display', displayOffCount) + ' Off'}
        />
      </Link>
    </Card>
  );
};
