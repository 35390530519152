import { AddCircle } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { DeleteButton, SaveButton } from '~/components/button';
import { AddPropertyGroupDialog } from '~/components/dialogs/add-property-group';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { FormLayout } from '~/components/settings';
import { useDevice } from '../context';
import { ActionBar, FormHeading, useSettingsForm } from '../lib';

export const SettingsForm = () => {
  const { device } = useDevice();
  const {
    addPropertyGroup,
    confirmDialogProps,
    deletePropertyGroup,
    filteredPropertyGroups,
    formik,
    generalSettings,
    propertyGroupSettings,
    setShowAddPropertyDialog,
    showAddPropertyDialog,
  } = useSettingsForm();

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate>
        <ActionBar>
          <Button
            disabled={!filteredPropertyGroups.length}
            onClick={() => setShowAddPropertyDialog(true)}
            startIcon={<AddCircle />}
            variant="contained"
          >
            Add Property Group
          </Button>
          <SaveButton
            disabled={!device.canUpdate.value || !formik.isValid || formik.isSubmitting}
            type="submit"
          >
            Save
          </SaveButton>
        </ActionBar>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
          <FormLayout formHeading="General" formData={generalSettings} />

          {propertyGroupSettings.map((settings) => (
            <FormLayout
              formHeading={
                <FormHeading>
                  <Box>{settings.name}</Box>
                  <DeleteButton
                    onClick={() =>
                      deletePropertyGroup({ propertyGroupId: settings.propertyGroupId })
                    }
                    size="small"
                    sx={{ fontSize: '0.7rem' }}
                  >
                    Remove Group
                  </DeleteButton>
                </FormHeading>
              }
              formData={settings.fields}
              key={settings.id}
            />
          ))}
        </Box>
      </form>
      <ConfirmDialog
        {...confirmDialogProps}
        title="Delete Property Group"
        confirm="Permanently Delete"
        prompt="Deleting this property group will remove it from this device."
        deleteConfirm
      />
      <AddPropertyGroupDialog
        description="Adding a new group to this device will add all settings that belong to that group."
        onSubmit={addPropertyGroup}
        open={showAddPropertyDialog}
        onClose={() => {
          setShowAddPropertyDialog(false);
        }}
        propertyGroups={filteredPropertyGroups}
      />
    </>
  );
};
