import { Button, TextField } from '@mui/material';
import { z } from 'zod';
import { Dialog, DialogForm, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { formatDate } from '~/lib/datetime';
import type { DeviceCommands__DeviceCommand as DeviceCommand } from '../../../../queries/commands.generated';

const commandSchema = z.object({
  command: z.string(),
  arbitrary: z.string().optional(),
});

export interface DetailsDialogProps {
  open: boolean;
  onClose: () => void;
  row: DeviceCommand | null;
}

export const DetailsDialog = ({ open, onClose, row }: DetailsDialogProps) => {
  const parsedCommand = commandSchema.parse(row?.command || { command: 'n/a' });

  return row ? (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle id="command-payload-dialog-title" onClose={onClose}>
        Command for {row.device.name}
      </DialogTitle>
      <DialogContent>
        <DialogForm>
          <label>
            <span className={`label`}>Command</span>
            <TextField value={parsedCommand.command} disabled fullWidth />
          </label>
          <label>
            <span className={`label`}>Created At</span>
            <TextField value={formatDate(row.createdAt)} disabled fullWidth />
          </label>
          <label>
            <span className={`label`}>Expires At</span>
            <TextField value={formatDate(row.expiresAt)} disabled fullWidth />
          </label>
          <label>
            <span className={`label`}>Status</span>
            <TextField value={row.status} disabled fullWidth />
          </label>
          <label>
            <span className={`label`}>Details</span>
            <pre
              style={{
                backgroundColor: '#f8f8f8',
                border: '1px solid #e6e6e6',
                borderRadius: '4px',
                color: 'rgba(0, 0, 0, 0.38)',
                fontSize: '.9em',
                width: '100%',
                padding: '8px',
              }}
            >
              {JSON.stringify(row.command || {}, null, 2)}
            </pre>
          </label>
        </DialogForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};
