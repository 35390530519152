import {
  AccessTime,
  Event,
  Folder,
  ImageAspectRatio,
  Layers,
  PhotoSizeSelectActual,
  Sell,
} from '@mui/icons-material';
import { useUpdateMediaItem } from '~/api/media-items';
import {
  DetailAccordion,
  DetailAccordionContainer,
  DetailAccordionDetails,
  DetailAccordionSummary,
} from '~/components/DetailAccordion';
import { DetailSection } from '~/components/DetailSection';
import { DetailSwitch } from '~/components/forms/details';
import { Link } from '~/components/link';
import { TagList } from '~/components/tag';
import { MediaKind } from '~/generated/graphql';
import { formatDate, formatInterval } from '~/lib/datetime';
import { mediaDimensions, mediaExtension, mediaSize } from '~/lib/media';
import { formatBytes } from '~/lib/units';
import type {
  MediaItemInfoProps__ContentFolder,
  MediaItemInfoProps__MediaItem,
} from './MediaItemInfo.generated';

/* GraphQL */ `#graphql
fragment MediaItemInfoProps__MediaItem on MediaItem {
  createdAt
  defaultDuration {
    ...Interval
  }
  id
  kind
  originalFile {
    id
    originalFilename
    uri
  }
  originalMetadata
  overlay {
    id
  }
  tags
  updatedAt
  useOriginal
}

fragment MediaItemInfoProps__ContentFolder on ContentFolder {
  id
  name
}
`;

export interface MediaItemInfoProps {
  contentFolder: MediaItemInfoProps__ContentFolder;
  mediaItem: MediaItemInfoProps__MediaItem;
}

export const MediaItemInfo = ({ contentFolder, mediaItem }: MediaItemInfoProps) => {
  const [updateMediaItem] = useUpdateMediaItem();

  return (
    <DetailAccordionContainer>
      <DetailAccordion defaultExpanded>
        <DetailAccordionSummary>Info</DetailAccordionSummary>
        <DetailAccordionDetails>
          <DetailSection
            icon="Aa"
            title="File"
            value={
              <Link to={mediaItem.originalFile.uri} target="_blank">
                {mediaItem.originalFile.originalFilename}
              </Link>
            }
          />
          <DetailSection
            icon={<PhotoSizeSelectActual />}
            title="Format"
            value={mediaExtension(mediaItem)}
          />
          <DetailSection
            icon={<Folder />}
            title="Folder"
            value={
              // There's no reason not to link to the root folder here
              // And this check is bad
              contentFolder.name === 'No Folder' ? (
                'n/a'
              ) : (
                <Link to="../..">{contentFolder.name}</Link>
              )
            }
          />
          <DetailSection
            icon={<Event />}
            title="Created At"
            value={formatDate(mediaItem.createdAt)}
          />
          <DetailSection
            icon={<Event />}
            title="Updated At"
            value={formatDate(mediaItem.updatedAt)}
          />
        </DetailAccordionDetails>
      </DetailAccordion>

      <DetailAccordion defaultExpanded>
        <DetailAccordionSummary>Specs</DetailAccordionSummary>
        <DetailAccordionDetails>
          <DetailSection icon="Kb" title="Size" value={formatBytes(mediaSize(mediaItem))} />
          <DetailSection
            icon={<ImageAspectRatio />}
            title="Dimensions"
            value={mediaDimensions(mediaItem).join('x')}
          />
          {mediaItem.kind === MediaKind.Video && (
            <DetailSection
              icon={<AccessTime />}
              title="Duration"
              value={formatInterval(mediaItem.defaultDuration)}
            />
          )}
        </DetailAccordionDetails>
      </DetailAccordion>

      <DetailAccordion defaultExpanded>
        <DetailAccordionSummary>Misc</DetailAccordionSummary>
        <DetailAccordionDetails>
          <DetailSection
            icon={<PhotoSizeSelectActual />}
            title="Use Original?"
            value={
              <DetailSwitch
                defaultChecked={mediaItem.useOriginal}
                disabled={mediaItem.kind === 'PDF'}
                onChange={(event) =>
                  void updateMediaItem({
                    variables: {
                      mediaItemId: mediaItem.id,
                      patch: { useOriginal: event.target.checked },
                    },
                  })
                }
                size="small"
              />
            }
          />
          <DetailSection
            icon={<Sell />}
            title="Tags"
            value={
              mediaItem.tags.length ? (
                <TagList
                  item={mediaItem}
                  sx={{ justifyContent: 'flex-end' }}
                  tags={mediaItem.tags}
                />
              ) : (
                '--'
              )
            }
          />
          {mediaItem.overlay && (
            <DetailSection icon={<Layers />} title="Overlay ID" value={mediaItem.overlay.id} />
          )}
        </DetailAccordionDetails>
      </DetailAccordion>
    </DetailAccordionContainer>
  );
};
