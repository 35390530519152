import { CheckCircle, Error, Info, OfflineBolt, Pending } from '@mui/icons-material';
import { Chip, useMediaQuery, useTheme } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { z } from 'zod';
import { ActionButton } from '~/components/button';
import { formatDate } from '~/lib/datetime';
import { capitalize, titleize } from '~/lib/string';
import type { DeviceCommands__DeviceCommand as DeviceCommand } from '../../../../queries/commands.generated';

const commandSchema = z.object({
  command: z.string(),
  arbitrary: z.string().optional(),
});

export const useColumns = (
  handleOpenDialog: (row: DeviceCommand) => void,
): GridColDef<DeviceCommand>[] => {
  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  const getStatusProps = (status: string) => {
    switch (status) {
      case 'DELIVERED':
        return { icon: <CheckCircle />, color: 'success' as const };
      case 'EXPIRED':
        return { icon: <Error />, color: 'error' as const };
      case 'PENDING':
        return { icon: <Pending />, color: 'primary' as const };
      case 'REALTIME':
        return { icon: <OfflineBolt />, color: 'tertiary' as const };
      default:
        return { icon: <Pending />, color: 'primary' as const };
    }
  };

  return useMemo(
    () => [
      {
        field: 'command',
        headerName: 'Command',
        sortable: false,
        renderCell: ({ row }) => {
          const parsedCommand = commandSchema.parse(row.command || { command: 'n/a' });
          return titleize(parsedCommand.command);
        },

        flex: 0.3,
        minWidth: 150,
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        sortable: false,
        renderCell: ({ row }) => {
          return formatDate(row.createdAt);
        },
        flex: 0.3,
        minWidth: 250,
      },
      {
        field: 'expiresAt',
        headerName: 'Expires At',
        sortable: false,
        renderCell: ({ row }) => (row.status === 'DELIVERED' ? '--' : formatDate(row.expiresAt)),
        flex: 0.3,
        minWidth: 250,
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 120,
        resizable: false,
        sortable: false,
        renderCell: ({ row }) => {
          const { icon, color } = getStatusProps(row.status);
          return <Chip icon={icon} label={capitalize(row.status)} color={color} />;
        },
      },
      {
        field: 'actions',
        type: 'actions',
        align: 'right',
        minWidth: isSmallAndUp ? 50 : 0,
        getActions: ({ row }) => {
          return [
            <ActionButton
              key={`view-${row.id}`}
              title="Details"
              Icon={Info}
              onClick={() => handleOpenDialog(row)}
            />,
          ];
        },
      },
    ],
    [isSmallAndUp, handleOpenDialog],
  );
};
